<template>
  <div class="content-wrapper__layout">
    <side-nav></side-nav>

    <div class="content-wrapper__main-content-wrapper content-wrapper__main-content-wrapper--31">
      <template v-if="!loading">
        <section class="main-content">
          <div class="section post-wrapper">
            <div class="post">
              <div class="post__header">
                <div class="post__author">
                  <div v-if="post.author?.profilePicture" class="user__image user__image--sm">
                    <img referrerpolicy="no-referrer" :src="post.author.profilePicture"> 
                  </div>
                  <div class="user__image" v-else>
                    <img src="@/assets/images/avatar_default.png">
                  </div>
                  <div class="user__data">
                    <h5>{{post.author?.fullName}}</h5>
                    <p class="post__time caption text-alt">{{$moment.utc(post.createdAt).fromNow()}}</p>
                  </div>
                </div>
                <div class="post__options">
                  <DropdownMenu>
                    <template #button>
                      <button class="btn btn--icon btn--circle">
                        <img src="@/assets/images/icons/ico_dots--bl.svg">
                      </button>
                    </template>
                    <template #options>
                      <a class="dropdown__option" href="#" @click.prevent="displayShareDialog(post)">
                        Compartir
                      </a>
                      <a v-if="!post.locked && (post.author.id == $store.state.user.id || $store.state.user.role_id == 1)" class="dropdown__option" href="#" @click.prevent="lockQna(post)">
                          No permitir más comentarios en esta publicación
                      </a>
                      <a v-else-if="post.locked && (post.author.id == $store.state.user.id || $store.state.user.role_id == 1)" class="dropdown__option" href="#" @click.prevent="lockQna(post)">
                          Volver a permitir comentarios en esta publicación
                      </a>
                    </template>
                  </DropdownMenu>
                </div>
              </div>
              <div class="post__main-content">
                <div class="post__causes-and-areas">
                  <img src="@/assets/images/icons/ico_book--gray.svg">
                  <div class="post__causes">
                    <template v-for="cause in post.causes" :key="cause.id">
                      <span class="caption post__cause">{{cause.name}}</span>
                    </template>
                    <template v-for="area in post.areas" :key="area.id">
                      <span class="caption post__cause">{{area.name}}</span>
                    </template>
                  </div>
                </div>
                <div class="post__title">
                  <h3>{{post.title}}</h3>
                </div>
                <div class="post__tags">
                  <template v-for="tag in post.tags" :key="tag.id">
                    <span class="tag tag--primary post__tag">
                      <router-link :to="{name: 'Filter', params: {filterType: 'etiquetas', filterId: tag.id} }">
                        {{tag.name}}
                      </router-link>
                    </span>
                  </template>
                </div>
                <div v-if="post.fileUrl && post.isImage" class="post__cover-image">
                  <img :src="post.fileUrl" />
                </div>
                <div class="post__content" v-html="this.post.description">
                </div>
                <div class="post__url" v-if="post.urlQna">
                  <h5 class="post__url-title">Enlace externo</h5>
                  <div class="post__url-info">
                    <img class="post__url-icon" src="@/assets/images/icons/ico_external-link--black.svg">
                    <a :href="post.urlQna" target="_blank">{{ post.urlQna }}</a>
                  </div>
                </div>
                <template v-if="post.fileUrl">
                  <div class="post__download" v-if="!post.isImage">
                    <a class="btn btn--primary" :href="post.fileUrl" target="_blank"><img class="icon" src="@/assets/images/icons/ico_download--white.svg"> Descargar archivo adjunto</a>
                  </div>
                </template>
              </div>
              <div class="post__footer">
                <div class="post__interactions-side">
                  <div class="post__interaction">
                    <button type="button" v-if="$store.state.user.id" :disabled="post.savingVote" class="btn btn--primary btn--text btn--sm" @click.prevent="vote(post)">
                      <template v-if="post.voted">
                        <img class="icon" src="@/assets/images/icons/ico_heart_filled--orange.svg"/> {{post.upvoteCount}}
                      </template>
                      <template v-else>
                        <img class="icon" src="@/assets/images/icons/ico_heart--orange.svg"/> {{post.upvoteCount}}
                      </template>
                    </button>
                    <button type="button" v-else class="btn btn--primary btn--text btn--sm"  @click.prevent="notSignedIn()">
                      <img class="icon" src="@/assets/images/icons/ico_heart_filled--orange.svg"/> {{post.upvoteCount}}
                    </button>
                  </div>
                  <div class="post__interaction">
                    <button class="btn btn--primary btn--text btn--sm">
                      <img class="icon" src="@/assets/images/icons/ico_comment--orange.svg"> {{[...(post.comments || [])].length}} {{post.entryType == 1 ? 'Respuestas' : 'Comentarios'}}
                    </button>
                  </div>
                </div>
                <div class="post__interactions-side">
                  <div class="post__interaction">
                    <button class="btn btn--icon btn--circle">
                      <img class="icon" src="@/assets/images/icons/ico_share--orange.svg" @click.prevent="displayShareDialog()">
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <!-- Comentario destacado -->
          <div class="section" v-if="highlightedComment">
            <div class="section__titles">
              <h5 class="section__title text-alt">{{ post.entryType == 1 ? 'Respuesta destacada' : 'Comentario destacado' }}</h5>
            </div>
            <div class="post-list">
              <div class="listed-post">
                <div class="post__header">
                  <div class="post__author">
                    <router-link :to="{name: 'Profile', params: {id: highlightedComment.commenter.id}}">
                      <div class="user__image" v-if="highlightedComment.commenter?.profilePicture">
                        <img referrerpolicy="no-referrer" :src="highlightedComment.commenter.profilePicture">
                      </div>
                      <div class="user__image" v-else>
                        <img src="@/assets/images/avatar_default.png">
                      </div>
                    </router-link>
                    <div class="user__data">
                      <h5>
                        <router-link :to="{name: 'Profile', params: {id: highlightedComment.commenter.id}}" >
                          {{highlightedComment.commenter.fullName}}
                        </router-link>
                      </h5>
                      <p class="post__time caption text-alt" :title="$moment.utc(highlightedComment.createdAt).local().format('L')">
                        <router-link :to="{name: 'Post', params: {id: highlightedComment.id}}" >
                          {{$moment.utc(highlightedComment.createdAt).fromNow()}}
                        </router-link>
                      </p>
                    </div>
                  </div>
                  <div class="post__options">
                    <DropdownMenu>
                      <template #button>
                        <button class="btn btn--icon btn--circle">
                          <img src="@/assets/images/icons/ico_dots--bl.svg">
                        </button>
                      </template>
                      <template #options>
                        <a v-if="highlightedComment.highlighted && (post.author.id == $store.state.user.id || $store.state?.user?.role_id == 1)" class="dropdown__option" href="#" @click.prevent="highlightComment(highlightedComment)">
                          Dejar de destacar
                        </a>
                        <a v-if="highlightedComment.commenter.id == $store.state.user.id || $store.state?.user?.role_id == 1" class="dropdown__option danger" href="#" @click.prevent="deleteComment(highlightedComment.id, true)">
                          Eliminar comentario
                        </a>
                      </template>
                    </DropdownMenu>
                  </div>
                </div>
                <div class="post__main-content">
                  <p>{{highlightedComment.comment}}</p>
                </div>
                <div class="post__footer">
                  <div class="post__interactions-side">
                    <div class="post__interaction">
                      <button :disabled="highlightedComment.savingVote" class="btn btn--primary btn--text btn--sm" @click.prevent="voteComment(highlightedComment)">
                        <template v-if="highlightedComment.voted">
                          <img class="icon" src="@/assets/images/icons/ico_heart_filled--orange.svg"/> {{highlightedComment.upvoteCount}}
                        </template>
                        <template v-else>
                          <img class="icon" src="@/assets/images/icons/ico_heart--orange.svg"/> {{highlightedComment.upvoteCount}}
                        </template>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Respuestas / Comentarios -->
          <div class="section">
            <div class="section__titles">
              <h5 class="section__title text-alt">{{post.entryType == 1 ? 'Preguntas' : 'Comentarios'}}</h5>
            </div>
            <div class="box box--no-shadow">
              <div class="section" v-if="$store.state.user.id">
                <!-- Nuevo comentario / respuesta -->
                <template  v-if="!post.locked">
                  <div class="form__group">
                    <textarea class="form__input" v-model="newComment" :placeholder="post.entryType == 1 ? 'Escribe tu respuesta' : 'Escribe tu comentario'"></textarea>
                  </div>
                  <div class="form__actions form__actions--xs">
                    <button class="btn btn--primary" :disabled="savingComment || newComment == ''" @click.prevent="saveComment">{{post.entryType == 1 ? 'Responder' : 'Comentar'}}</button>
                  </div>
                </template>
                <!-- Comentarios / Respuestas desactivadas-->
                <template v-else>
                  <div class="text-center text-alt">
                    <br>
                    <p>{{post.entryType == 1 ? 'Las nuevas respuestas a esta publicación están deshabilitadas' : 'Los nuevos comentarios a esta publicación están deshabilitados'}}</p>
                  </div>
                </template>
              </div>
              <div class="section" v-else>
                <div class="form__group">
                  <textarea class="form__input" @click.prevent="notSignedIn()" :placeholder="post.entryType == 1 ? 'Escribe tu respuesta' : 'Escribe tu comentario'"></textarea>
                </div>        
                <div class="form__actions form__actions--xs">
                  <button class="btn btn--primary" :disabled="true">{{post.entryType == 1 ? 'Responder' : 'Comentar'}}</button>
                </div>    
              </div>
              <div class="section" v-if="post.comments?.length">
                <div class="section__titles">
                  <h5 class="section__title text-alt"></h5>
                  <div class="form__group form__group--sm form__group--auto-width" v-if="post.comments">
                    <select class="form__input" name="" id="" v-model="commentSortOrder" @change.prevent="sortComments()">
                      <option :value="null" selected disabled>Ordenar por</option>
                      <option value="oldest">Orden de creación</option>
                      <option value="newest">Más recientes</option>
                      <option value="most-upvotes">Más votadas</option>
                    </select>
                  </div>
                </div>
                <br>
                <div class="post-list comment-list">
                  <div class="listed-post" v-for="comment in post.comments.filter(n => !n.highlighted)" :key="comment.id">
                    <div class="post__header">
                      <div class="post__author">
                        <router-link :to="{name: 'Profile', params: {id: comment.commenter.id}}">
                          <div class="user__image" v-if="comment.commenter?.profilePicture">
                            <img referrerpolicy="no-referrer" :src="comment.commenter.profilePicture">
                          </div>
                          <div class="user__image" v-else>
                            <img src="@/assets/images/avatar_default.png">
                          </div>
                        </router-link>
                        <div class="user__data">
                          <h5>
                            <router-link :to="{name: 'Profile', params: {id: comment.commenter.id}}" >
                              {{comment.commenter.fullName}}
                            </router-link>
                          </h5>
                          <p class="post__time caption text-alt" :title="$moment.utc(comment.createdAt).local().format('L')">
                            <router-link :to="{name: 'Post', params: {id: comment.id}}" >
                              {{$moment.utc(comment.createdAt).fromNow()}}
                            </router-link>
                          </p>
                        </div>
                      </div>
                      <div class="post__options" v-if="(highlightedComment == null && post.author.id == $store.state.user.id) || comment.commenter.id == $store.state.user.id">
                        <DropdownMenu>
                          <template #button>
                            <button class="btn btn--icon btn--circle">
                              <img src="@/assets/images/icons/ico_dots--bl.svg">
                            </button>
                          </template>
                          <template #options>
                            <a v-if="highlightedComment == null && (post.author.id == $store.state.user.id || $store.state?.user?.role_id == 1)" class="dropdown__option" href="#" @click.prevent="highlightComment(comment)">
                              Destacar
                            </a>
                            <a v-if="comment.commenter.id == $store.state.user.id || $store.state?.user?.role_id == 1" class="dropdown__option danger" href="#" @click.prevent="deleteComment(comment.id), false">
                              Eliminar
                            </a>
                          </template>
                        </DropdownMenu>
                      </div>
                    </div>
                    <div class="post__main-content">
                      <p>{{comment.comment}}</p>
                    </div>
                    <div class="post__footer">
                      <div class="post__interactions-side">
                        <div class="post__interaction">
                          <button v-if="$store.state.user.id" :disabled="comment.savingVote" class="btn btn--primary btn--text btn--sm" @click.prevent="voteComment(comment)">
                            <template v-if="comment.voted">
                              <img class="icon" src="@/assets/images/icons/ico_heart_filled--orange.svg"/> {{comment.upvoteCount}}
                            </template>
                            <template v-else>
                              <img class="icon" src="@/assets/images/icons/ico_heart--orange.svg"/> {{comment.upvoteCount}}
                            </template>
                          </button>
                          <button v-else type="button" class="btn btn--primary btn--text btn--sm" @click.prevent="notSignedIn()">
                            <img class="icon" src="@/assets/images/icons/ico_heart_filled--orange.svg"/> {{comment.upvoteCount}}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </template>
      <Loader v-else viewBox="0 0 300 200">
        <rect x="0" y="0" rx="3" ry="3" width="250" height="30" />
        <rect x="20" y="40" rx="3" ry="3" width="260" height="15" />
        <rect x="20" y="70" rx="3" ry="3" width="260" height="15" />
        <rect x="20" y="100" rx="3" ry="3" width="170" height="15" />
      </Loader>
    </div>
  </div>
  <share-dialog v-if="showShareDialog" :sharing="sharing" @close="showShareDialog = false"></share-dialog>
  <register-dialog v-if="showRegisterDialog" @close="showRegisterDialog = false"></register-dialog>
</template>
<script>
  import SideNav from '@/components/SideNav.vue'
  import ShareDialog from '@/components/ShareDialog.vue'
  import { ContentLoader as Loader } from 'vue-content-loader'
  import DropdownMenu from '@/components/DropdownMenu.vue'
  import RegisterDialog from '@/components/RegisterDialog.vue'

  export default {
    name: 'PostView',
    components: {
      SideNav,
      Loader,
      ShareDialog,
      DropdownMenu,
      RegisterDialog
    },
    data() {
      return {
        post: {},
        loading: true,
        newComment: '',
        savingComment: false,
        showShareDialog: false,
        sharing: {},
        commentSortOrder: 'oldest',
        highlightedComment: null,
        showRegisterDialog: false
      }
    },
    async created() {
      await this.loadData()
    },
    methods: {
      async loadData(){
        this.loading = true
        const res =(await this.$axios.get(`Qna/post/${this.$route.params.id}`))
        if(res.status == 200){
          this.post = res.data
          document.title = this.post.title + ' - Dorapp'
          if(this.post.comments){
            const highlighted = res.data.comments.find(obj => obj.highlighted)
            //this.post.comments = res.data.comments.filter( obj => !obj.highlighted)  
              if(highlighted)
                this.highlightedComment = highlighted
          }
          this.loading = false
        }else{
          this.$toast.error(res.data)
          this.$router.push({name: 'Home'})
        }
      },
      async vote() {
        this.post.savingVote = true
        const res = (await this.$axios.post('Qna/vote', {post_id: this.post.id}))
        if(res.status == 200){
          this.post.voted = res.data.voted
          this.post.upvoteCount = res.data.upvoteCount
        }
        this.post.savingVote = false
      },
      async voteComment(comment) {
        comment.savingVote = true
        const res = (await this.$axios.post('Qna/vote-comment', {comment_id: comment.id}))
        if(res.status == 200){
          comment.voted = !comment.voted
          comment.upvoteCount = res.data
        }
        comment.savingVote = false
      },
      async saveComment(){
        this.savingComment = true
        const res = await this.$axios.post(`Qna/comment/${this.post.id}`,{comment: this.newComment})
        if(res.status == 200){
          this.post.comments.push(res.data)
            this.newComment = ''
            if (this.post.entryType == 1) {
                this.$toast.success('Tu respuesta ha sido creada exitosamente')
            } else {
                this.$toast.success('Tu comentario ha sido creado exitosamente')
            }
        }
        this.savingComment = false
      },
      async deleteComment(id, highlighted){
        if(confirm('¿Deseas eliminar este comentario?')){
          const res = await this.$axios.post('Qna/delete-comment', {comment_id: id})
          if(res.status == 200){
            this.$toast.success('El comentario ha sido eliminado exitosamente')
            this.post.comments = this.post.comments.filter( obj => obj.id !== id)
            if(highlighted)
              this.highlightedComment = null
          }
          else
            this.$toast.error(res.data)
        }
      },
      async highlightComment(comment){
        const res = await this.$axios.post('Qna/highlight-comment', {comment_id: comment.id})
        if(res.status == 200){
          comment.highlighted = !comment.highlighted
          if(comment.highlighted){
            this.$toast.success('El comentario seleccionado has sido marcado como destacado')
            const auxHighlights = this.post.comments.find(obj => obj.id == comment.id)
            if(auxHighlights)
              this.highlightedComment = auxHighlights
            //this.post.comments = this.post.comments.filter( obj => obj.id !== comment.id)
          }
          else{
            this.$toast.success('El comentario destacado ha sido removido')
            //const auxHighlights = this.highlightedComments.find(obj => obj.id == comment.id)
            //if(auxHighlights)
            //  this.post.comments.push(auxHighlights)
            //if(this.highlightedComments.length > 0)
            //console.log(JSON.parse(JSON.stringify(this.post.comments)))
            //this.post.comments.find(n => n.id == comment.id).highlighted = false
            this.highlightedComment = null
          }
        }
        else
          this.$toast.error(res.data)
      },
      displayShareDialog(){
        this.showShareDialog = true
        this.sharing.url = `${window.location.origin}/publicacion/${this.post.id}`
        this.sharing.title = this.post.title || this.post.description
        //this.sharing.description = this.post.description
        //this.sharing.quote = 'quote'
        this.sharing.hashtags = this.post.tags.map(n => n.name).join(',')
        //this.sharing.twitterUser = 'twitterUser'
      },
      sortComments(){
        switch(this.commentSortOrder){
          case 'newest':
            this.post.comments.sort((a, b) => a.created_at < b.created_at ? 1 : -1)
            break;
          case 'oldest':
            this.post.comments.sort((a, b) => a.created_at > b.created_at ? 1 : -1)
            break;
          case 'most-upvotes':
            this.post.comments.sort((a, b) => a.upvoteCount < b.upvoteCount ? 1 : -1)
            break;
        }
      },
      lockQna(post){
        if(confirm(`¿Está seguro de que desea ${post.locked ? 'desbloquear' : 'bloquear'} la ${post.entryType === 1 ? 'Pregunta' : 'Publicación'}?`)){
          this.$axios.post('Qna/lock', {qna_id: post.id}).then(res => {
            if(res.status == 200){
              post.locked = !post.locked
              this.$toast.success(`${post.entryType === 1 ? 'La pregunta' : 'La publicación'} ${!post.locked ? 'ha sido desbloqueada exitosamente' : 'ha sido bloqueada exitosamente'}`)
            }
          })
        }
      },
      closeRegisterModal() {
      this.showRegisterDialog = false
      },
      notSignedIn() {
        this.showRegisterDialog = true
      }
    },
    watch:{
      "$route": async function(route) {
        if(route.name == 'Post')
          await this.loadData()
      }
    }
  }
</script>
<style>
  .ql-align-right {
    text-align: right;
  }
  .ql-align-center {
    text-align: center;
  }
  .ql-align-left {
    text-align: left;
  }
  .ql-video {
    display: block;
    max-width: 100%;
  }
  .ql-video.ql-align-center {
    margin: 0 auto
  }
  .ql-video.ql-align-right {
    margin: 0 0 0 auto
  }
</style>